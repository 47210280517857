$fa-font-path: '~font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';

@import 'variables';

@mixin phone {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@import 'globals';
@import 'bars';
@import 'modals';
@import 'widgets';
@import 'form';
@import 'footer';
@import 'gallery';
@import 'facebook';
@import 'data-protection';
