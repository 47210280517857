$color-green: #7bb064;
$color-white: #fff;
$color-black: #000;
$color-background: #e3e8e5;
$color-background-widget: #fff;
$color-border: #c1c1c1;
$color-border-hover: #b0b0b0;
$color-button: $color-green;
$color-button-hover: lighten($color-green, 20%);
$color-button-gallery: $color-border;
$color-background-button-gallery-hover: #333;
$color-grey-logo: #6f6f6f;

$time-hover: .2s;

$margin-widgets: 20px;
$width-max-modal: 1000px;
$height-max-gallery: 700px;

$tablet-width: 768px;
$desktop-width: 1024px;

$style-border: $color-border solid 1px;
$style-border-hover: $color-border-hover solid 1px;
