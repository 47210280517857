.gallery {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -1px;
  }

  &__thumbnail {
    height: 75px;
    margin: 1px;

    > img {
      height: 100%;
    }

    &:hover {
      cursor: pointer;
      opacity: .7;
    }
  }
}

.gallerymodal {
  align-items: center;
  background-color: rgba($color-black, .8);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;

  @mixin gallerybutton {
    background-color: transparent;
    color: $color-button-gallery;
    position: fixed;
    padding: 20px;
    outline: none;
  }

  &__close {
    @include gallerybutton;
    right: 0;
    top: 0;
    border: 0;
    z-index: 300;

    &:hover {
      background-color: $color-background-button-gallery-hover;
      cursor: pointer;
    }
  }

  @mixin gallerynav {
    height: 100%;
    position: fixed;
    width: 50%;
  }

  @mixin gallerynavbutton {
    @include gallerybutton;
    height: 100%;
    max-width: 50%;
    width: 200px;

    &:hover {
      background-color: transparent;
    }
  }

  &__next {
    @include gallerynav;
    right: 0;

    &__button {
      @include gallerynavbutton;
      right: 0;
      text-align: right;
      border: 0;
    }
  }

  &__prev {
    @include gallerynav;
    left: 0;

    &__button {
      @include gallerynavbutton;
      left: 0;
      text-align: left;
      border: 0;
    }
  }

  &__imgwrapper {
    box-shadow: 0 0 100px 1px $color-black;
		max-height: 100%;
		max-width: 100%;
		height: $height-max-gallery;
    width: $width-max-modal;
  }

  &__img {
    height: 100%;
    width: 100%;
    background-position: center;
		background-repeat: no-repeat;
    background-size: contain;
  }
}
