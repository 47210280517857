body {
  background-color: $color-background;
  font-family: 'Open Sans', sans-serif;
  -webkit-tap-highlight-color: transparent;
	line-height: 22px;
}

@mixin button {
  background-color: $color-button;
  border: 0;
  border-radius: 0;
  outline: none;
  padding: 20px;
  transition: background-color $time-hover linear;

  &:hover {
    background-color: $color-button-hover;
  }
}

.button--close {
  @include button;

  color: $color-white;
}

table {
  tr {
    border-left: $style-border;
    border-top: $style-border;

    th {
      border-bottom: $style-border;
      border-right: $style-border;
      font-weight: bold;
      padding: 10px;
      text-align: left;

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    td {
      border-bottom: $style-border;
      border-right: $style-border;
      padding: 10px;
    }
  }
}

h1 {
  font-size: 25px;
  margin: 0;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

ul {
  list-style-type: none;
  padding-left: 0;

  > li {
    &:not(:first-child) {
      margin-top: 5px;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.li {
  align-items: center;
  display: flex;

  &__bullet {
    background-color: $color-black;
    border-radius: 2px;
    height: 4px;
    margin-right: 5px;
    width: 4px;
  }
}

@mixin splitview {
  .splitview {
    display: flex;
  }

  .splitview__right {
    padding-left: 20px;
    width: 100%;

    > *:first-child {
      margin-top: 0;
    }
  }
}

@include desktop {
  @include splitview;
}

@include tablet {
  @include splitview;
}

.text--center {
  text-align: center;
}

.text--red {
  color: #b0647b;
}

.link {
  color: $color-black;
  text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.infoicon {
  font-size: 25px;
  text-align: center;
}
