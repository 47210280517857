.modal {
  align-items: center;
  background-color: rgba($color-black, .3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;

  &--animation-enter { 
    transform: scale(1, .5);
    transform-origin: center;

    &.modal--animation-enter-active {
      transform: scale(1);
      transition: all .5s cubic-bezier(.09, 1.14, .35, 1);
    }
  }

  &__scroll {
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  &__visible {
    background: $color-white;
    margin: 25px auto;
    max-width: $width-max-modal;
  }

  &__header {

    &__content {
      align-items: center;
      background: $color-green;
      color: $color-white;
      display: flex;
      justify-content: space-between;
    }

    &--fixed {
      max-width: $width-max-modal;
      position: fixed;
      top: 0;
      width: 100%;
    }

    &__title {
      padding: 0 0 0 40px;
    }

    &__close {
      align-self: flex-start;
    }
  }

  &__content {
    padding: 40px;

    &--fixed {
      //box-shadow: 0 0 5px 1px $color-border;
      padding: 68px 20px 20px;
    }

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    table {
      table-layout: fixed;
      width: 100%;

      td {
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
    }

    &--contact {
      background: url("../imgs/entry.jpg") no-repeat center center fixed;
      background-size: cover;
      background-attachment: scroll;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0 20px 0 20px;

      & > div {
        max-width: 450px;
        width: 100%;
        margin-top: 20px;
        &:first-child {
          background-color: $color-white;
          box-sizing:border-box;
          padding: 20px;
        }
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__contact__form {
    height: 807px;
    @media (min-width: 680px) {
      height: 913px;
    }
    width: 100%;
  }

  .h2--space {
    padding-top: 15px;
  }
}

.googlemap {
  height: 100%;
  width: 100%;
}

.square {
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  &__inner {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
