.bar {
  background-color: $color-green;
  color: $color-background;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__img--company {
    opacity: .5;
    position: absolute;
    right: 0;
    top: -50px;
  }

  &__title {
    font-size: 60px;
    padding: 60px 0 40px;
    position: relative;
    text-align: center;
    z-index: 100;
  }

  &__subtitle {
    display: flex;
    font-style: italic;
    justify-content: center;
    padding: 0 0 40px;
    position: relative;
    text-align: center;
    z-index: 100;
  }

  &__link {
    color: $color-background;
  }

  &__infos {
    padding: 0 0 40px;
    position: relative;
    z-index: 100;

    @include phone {
      display: flex;
      justify-content: center;
    }

    &__group {
      @mixin content-group {
        display: flex;
        justify-content: center;
      }

      @include desktop {
        @include content-group;
      }

      @include tablet {
        @include content-group;
      }
    }

    &__info {
      padding: 0 10px;

      @include phone {
        &:not(:first-child) {
          padding-top: 5px;
        }
      }
    }
  }

  &__next {
    display: flex;
    justify-content: center;
    padding: 0 0 20px;
    position: relative;
    z-index: 100;
  }
}

.button--next {
  background-color: transparent;
  border: 1px solid;
  border-radius: 50%;
  height: 50px;
  transition: all $time-hover linear;
  width: 50px;

  &:hover {
    background-color: transparent;
    box-shadow: 0 0 5px 1px $color-background;
  }
}
