.board {
  margin: auto;
  max-width: 1000px;
  overflow: hidden;

  &__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: $margin-widgets (-$margin-widgets);
  }
}

.widget {
  background-color: $color-background-widget;
  display: block;
  height: 240px;
  margin: $margin-widgets;
  outline: $style-border;
  overflow: hidden;
  transition: all $time-hover linear;
  width: 300px;

  &:hover {
    box-shadow: 0 0 5px 1px $color-border;
    cursor: pointer;
    outline: $style-border-hover;
    text-decoration: underline;
  }

  &__img {
    height: 67%;
    overflow: hidden;
    width: 100%;

    > img {
      width: 100%;
    }
  }

  &__text {
    align-items: center;
    display: flex;
    height: 33%;
    justify-content: center;
    text-align: center;
  }
}
