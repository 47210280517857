.footers {
  border-top: 1px solid $color-border-hover;
  color: $color-grey-logo;
  padding: 40px 0 20px 0;

  &__inner {
    display: flex;
    font-style: italic;
    justify-content: center;
    margin: 0 auto;
    max-width: 1000px;
    padding-bottom: 20px;

    img {
      max-height: 50px;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding-bottom: 20px;

    a {
      color: black;
    }
  }
}
